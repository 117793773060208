<template>
  <div ref="heroBlock" class="pages-common-hero">
    <div class="pages-common-hero__wrap">
      <!-- Hero Img -->
      <div class="pages-common-hero__preview__image" v-if="props.hero?.image">
        <WidgetPicture
          class="is-cover"
          :class="{ 'hide-for-small': props.hero.imageMobile }"
          :image="props.hero?.image"
          :alt="props.hero.title"
        />

        <!-- Hero Img Mobile (se presente) -->
        <WidgetPicture
          v-if="props.hero.imageMobile"
          class="is-cover show-for-small"
          :image="props.hero?.imageMobile"
          :alt="props.hero.title"
        />
      </div>

      <!-- Hero Video -->
      <div v-if="hasPreview" class="pages-common-hero__preview__video">
        <WidgetVideo
          class="is-cover"
          :videoUrl="props.hero?.previewVideo"
          :loop="true"
          :forcePlay="true"
          :autoplay="true"
          :muted="true"
          :controls="false"
        />
      </div>

      <!-- Hero Text Block -->
      <div class="pages-common-hero__text__wrap">
        <div
          class="pages-common-hero__text__content row-1"
          :class="{ '--white-text': props.hero?.whiteText }"
        >
          <div
            v-if="props.hero?.title"
            data-animate="preloader-reveal"
            class="pages-common-hero__title"
            v-html="props.hero.title"
          />
          <div
            v-if="props.hero?.subtitle"
            data-animate="preloader-opacity"
            class="pages-common-hero__subtitle text-sans-m"
            v-html="props.hero.subtitle"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PagesCommonHero',
}
</script>

<script setup>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

const props = defineProps({
  hero: Object,
})

const isInView = ref(null)
const heroBlock = ref(null)

const lightTheme = setLightTheme()
const route = useRoute()

const lenis = inject('lenis')

const hasPreview = computed(() => props.hero?.previewVideo)
const hasQuickView = computed(() => props.hero?.overlayFullVideo)

const quickview = useState('quickview', () => null)

const blockOverlay = ref(null)
const blockOverlayVideo = ref(null)

const showableCards = ref(false)

const duration = 0.6
const durationFast = 0.4
const durationSlow = 1
const ease = 'power2.inOut'

watch(
  () => isInView.value,
  (isInView) => {
    if (isInView && props.hero.whiteText) {
      document.body.classList?.add('--light-theme')
      lightTheme.value = true
    } else {
      document.body.classList?.remove('--light-theme')
      lightTheme.value = false
    }
  },
)

onUnmounted(() => {
  ScrollTrigger.refresh()
  isInView.value = true
})

onMounted(() => {
  isInView.value = true
  if (isInView.value && props.hero.whiteText) {
    document.body.classList?.add('--light-theme')
    lightTheme.value = true
  }

  ScrollTrigger.create({
    id: 'scrollHero',
    start: 'top top-=5px',
    end: 'bottom top+=40px',
    trigger: heroBlock.value,
    onEnter: () => {
      //console.log('enter scroll')
      isInView.value = true
      /*if (props.hero.whiteText) {
        document.body.classList?.add('--light-theme')
        lightTheme.value = true
      }*/
    },
    onEnterBack: () => {
      //console.log('enter back scroll')
      isInView.value = true
      /*if (props.hero.whiteText) {
        document.body.classList?.add('--light-theme')
        lightTheme.value = true
      }*/
    },
    onLeave: () => {
      //console.log('leave scroll')
      isInView.value = false
    },
    onLeaveBack: () => {
      //console.log('leave back scroll')
      isInView.value = true
      /*if (props.hero.whiteText) {
        console.log(isInView.value)
        document.body.classList?.add('--light-theme')
        lightTheme.value = true
      }*/
    },
  })

  setTimeout(() => {
    if (isInView.value && props.hero.whiteText) {
      document.body.classList?.add('--light-theme')
      lightTheme.value = true
      //console.log('mounted hero timeout if inview -> light true')
    }
  }, 500)
})

watch(
  () => route.path,
  () => {
    if (
      !route?.name?.includes('contacts') &&
      !route?.name?.includes('lets-talk') &&
      !route?.name?.includes('expertise-slug')
    ) {
      document.body.classList?.remove('--light-theme')
      lightTheme.value = false
    }

    isInView.value = true
    //lenis.value.scrollTo(0)
    //
    ScrollTrigger.getById('scrollHero')?.kill(true)
  },
)

const onQuickView = (event) => {
  event.stopPropagation() // Previene la propagazione del click al link
  event.preventDefault() // Previene l'azione predefinita del bottone
  quickview.value = props.hero?.overlayFullVideo
}
</script>

<style lang="scss">
@import './style.scss';

.v-enter-active,
.v-leave-active {
  transition: opacity $speed-fast ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
