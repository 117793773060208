<template>
  <div class="page page-home p-bottom-xl-1">
    <PagesCommonHero v-if="home?.hero" :hero="home?.hero" />

    <WidgetIntroTextBlock
      v-if="home?.introBlock && home?.introBlock.introText"
      :text="home.introBlock.introText"
      :data="home.introBlock"
    />

    <!-- Body -->
    <template v-if="home?.body && home.body?.length">
      <PagesCommonBody :body-items="home.body" :touch="touch" />
    </template>
  </div>
</template>

<script setup>
import queries from '@/graphql/dato/queries/index.js'
import pageTransition from '@/assets/js/transitions/helper.js'
import { useDatoStore } from '@/store/dato.js'

const { menuCategories, menuProducts } = useDatoStore()

definePageMeta({
  layout: 'default',
  pageTransition,
})

const { locale } = useI18n()

const touch = ref(false)

const query = queries.home.getHome
const variables = computed(() => ({
  locale: locale?.value ? locale.value : 'it',
}))

const { data } = await useGraphql(query, variables)

if (!data.value?.home) {
  throw createError({ statusCode: 404 })
}

const menuCat = computed(() => menuCategories)
const menuProd = computed(() => menuProducts)

onMounted(() => {
  touch.value = 'ontouchstart' in window || !!navigator.maxTouchPoints /// !! trasformano 0/1 in Bool
})

const home = computed(() => data?.value?.home)
const seo = computed(() => data?.value?.home?.seo || {})

// Seo
useSeo({
  ...seo.value,
})
</script>
